<template>
  <canvas
    ref="canvas"
    class="confetti-canvas absolute inset-0" />
</template>

<script lang="ts" setup>
import confetti from "canvas-confetti";

const eventStore = useEventStore();
const canvas = ref<HTMLCanvasElement>();
const store = useCanvasStore();
const confettiInstance = ref<confetti.CreateTypes>();

const colors = [
  "#FFCBA4", // Light peach
  "#FFB6C1", // Light pink
  "#9370DB", // Medium purple
  "#ADD8E6", // Light blue
  "#ff6a89", // Medium pink
];

function randomInRange ( min: number, max: number ) {
  return Math.random() * ( max - min ) + min;
}

const triggerFall = () => {
  const duration = 15 * 1000;
  const animationEnd = Date.now() + duration;
  let skew = 1;

  ( function frame () {
    const timeLeft = animationEnd - Date.now();
    const ticks = Math.max( 200, 500 * ( timeLeft / duration ) );
    skew = Math.max( 0.8, skew - 0.001 );

    const randomColor = colors[Math.floor( Math.random() * colors.length )];

    try {
      confettiInstance.value?.( {
        particleCount: 1,
        startVelocity: 0,
        ticks: ticks,
        origin: {
          x: Math.random(),
          // since particles fall down, skew start toward the top
          y: ( Math.random() * skew ) - 0.2,
        },
        colors: [randomColor],
        gravity: randomInRange( 0.4, 0.6 ),
        scalar: randomInRange( 0.4, 1 ),
        drift: randomInRange( -0.4, 0.4 ),
      } );
    }
    catch ( err ) {
      console.warn( "booboo", err );
    }

    if ( eventStore.phase === "postEvent" ) {
      requestAnimationFrame( frame );
    }
  }() );
};

const triggerConfetti = () => {
  const interval = 600;
  const variance = 2000;

  const defaults = {
    angle: 90,
    spread: 60,
    startVelocity: 50,
    ticks: 300,
    colors,
    scalar: 0.6,
  };

  function fire ( particleRatio: number, opts: confetti.Options ) {
    getAverageFrameRate( ( averageFrameRate: number ) => {
      const particleCount = averageFrameRate < 30 ? 50 : 100;

      try {
        confettiInstance.value( Object.assign( {}, defaults, opts, { particleCount } ) );
      }
      catch ( err ) {
        console.warn( "fgdfgdfg", err );
      }
    } );
  }

  const fireFromMultiplePoints = ( points: number ) => {
    for ( let i = 0; i < points; i++ ) {
      const xOffset = i / ( points - 1 ); // Calculate xOffset for each point
      const delay = i === 0 ? 0 : Math.random() * variance; // Add variance to delay

      setTimeout( () => {
        fire( 0.1, {
          origin: { x: xOffset, y: 1 },
        } );
      }, delay );
    }
  };

  fireFromMultiplePoints( 7 );
  const intervalId = setInterval( () => {
    fireFromMultiplePoints( 7 );
  }, interval );

  setTimeout( () => {
    clearInterval( intervalId );
  }, 2000 );
};

function getAverageFrameRate ( callback: ( avgFrameRate: number )=> void ) {
  let frameCount = 0;
  const startTime = performance.now();

  function checkFrameRate () {
    frameCount++;
    const currentTime = performance.now();
    if ( currentTime - startTime < 1000 ) {
      requestAnimationFrame( checkFrameRate );
    }
    else {
      const averageFrameRate = ( frameCount / ( currentTime - startTime ) ) * 1000;
      callback( averageFrameRate );
    }
  }

  requestAnimationFrame( checkFrameRate );
}

watch( () => eventStore.phase, ( val ) => {
  if ( val === "postEvent" ) {
    triggerFall();
  }
} );

onMounted( () => {
  if ( !canvas.value ) {
    throw createError( "Unable to get canvas" );
  }

  confettiInstance.value = confetti.create( canvas.value, {
    resize: true,
    useWorker: false,
    disableForReducedMotion: true,
  } );

  watch( () => store.confetti, ( newVal ) => {
    if ( newVal ) {
      triggerConfetti();
    }
  } );

  if ( eventStore.phase === "postEvent" ) {
    triggerFall();
  }
} );
</script>

<style scoped>
.confetti-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
